import React, { useRef, useState, useLayoutEffect } from 'react';
import { useViewportScroll, useTransform, motion } from 'framer-motion';

export const ParallaxBox = ({
  children,
  yOffset = 100, // number > 0
  easing = [0.42, 0, 0.58, 1],
  triggerPoint = 1, // value between 0 and 1 (top and bottom of the window),
  ...rest
}) => {
  const { scrollY } = useViewportScroll();
  const ref = useRef();
  const [elementTop, setElementTop] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);

  useLayoutEffect(() => {
    if (!ref.current) return;

    const setValues = () => {
      setElementTop(ref.current.offsetTop);
      setClientHeight(window.innerHeight);
    };

    setValues();
    document.addEventListener('load', setValues);
    window.addEventListener('resize', setValues);

    return () => {
      document.removeEventListener('load', setValues);
      window.removeEventListener('resize', setValues);
    };
  }, [ref, yOffset]);

  const transformInitialValue = elementTop - clientHeight;
  const transformFinalValue = elementTop + yOffset;

  const yRange = [transformInitialValue, transformFinalValue];

  const y = useTransform(scrollY, yRange, [0, -yOffset], easing);

  return (
    <motion.div ref={ref} initial={{ y: 0 }} style={{ y, willChange: 'transform' }} {...rest}>
      {children}
    </motion.div>
  );
};
