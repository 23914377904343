import React from 'react';
import cx from 'classnames';

import { ModuleWrapper } from '../moduleWrapper';
import { SanityImage } from '@components/sanity/sanityImage';
import { ParallaxBox } from '@components/animation/parallaxBox';

const EditorialImages = ({ config, imageOne, imageTwo, layout }) => {
  return (
    <ModuleWrapper {...config}>
      <div className="grid grid-cols-14 gap-y-4 md:gap-y-0">
        {layout === 'left' ? (
          <>
            {/* Image One */}
            <ParallaxBox
              className={cx('col-start-2 col-end-14 md:row-start-1 md:row-span-3 relative', {
                'md:col-start-8 md:col-end-14 z-10 lg:col-start-9': layout === 'left',
                'md:col-start-2 md:col-end-8 z-0 lg:col-end-7': layout === 'right',
              })}
              yOffset={200}
            >
              <SanityImage image={imageOne} width={1000} />
            </ParallaxBox>
            {/* Image Two */}
            <div
              className={cx('relative col-start-2 col-end-14 md:row-start-2 md:row-span-3 ', {
                'col-start-2 col-end-12 z-0 lg:col-end-11': layout === 'left',
                'col-start-4 col-end-14 z-10 lg:col-start-5': layout === 'right',
              })}
            >
              <SanityImage image={imageTwo} width={1000} />
            </div>
          </>
        ) : (
          <>
            {/* Image One */}
            <div
              className={cx('col-start-2 col-end-14 md:row-start-1 md:row-span-3 relative', {
                'md:col-start-8 md:col-end-14 z-10 lg:col-start-9': layout === 'left',
                'md:col-start-2 md:col-end-8 z-0 lg:col-end-7': layout === 'right',
              })}
            >
              <SanityImage image={imageOne} width={1000} />
            </div>
            {/* Image Two */}
            <ParallaxBox
              className={cx('relative col-start-2 col-end-14 md:row-start-2 md:row-span-3 ', {
                'col-start-2 col-end-12 z-0 lg:col-end-11': layout === 'left',
                'col-start-4 col-end-14 z-10 lg:col-start-5': layout === 'right',
              })}
              yOffset={200}
            >
              <SanityImage image={imageTwo} width={1000} />
            </ParallaxBox>
          </>
        )}
      </div>
    </ModuleWrapper>
  );
};

export default EditorialImages;
